*{ 
    margin: 0;
    box-sizing: border-box;
}

.billpage_maincontainer{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: baseline;

    .bill_box{
        width: auto;
        height: auto;
        background-color: #fff; 

        
        .billbox_headlogo{
            display: flex;
            justify-content: center;

            .saltshaker_logo{
                width: auto;
                height: auto;
            }
        }
    

    .billbox_headcontent{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;

        h2{
            font-size: 18px;
            font-weight: 700;
        }
        small{
            font-size: 11px;
            font-weight: 400;
            opacity: 0.6;
        }
    }

    .billbox_datetimedetails{
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 15px 75px;
       
        .billbox_datetimecontent{
            display: flex;
            align-items: center;
            gap: 5px; 

            img{
                width: auto;
                height: auto;
            }
            span{
                font-size: 16px;
            }
        }
    }

    .billbox_itemamountdetails{
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 5px;

        .billbox_itemcontent{
            display: flex;
            justify-content: space-between;
            h4{
                font-size: 14px;
                font-weight: 500;
            }
            span{
                font-size: 14px;
                font-weight: 500;
            }
            .billbox_discountcontent{
                color: #FF0000;
            }
        }
    }


    .billbox_linerow{
        display: flex;
        justify-content: center;
        padding: 8px 15px;
        .billbox_line{
            width: 100%;
            height: auto;
        }
    }

    .billbox_editrow{
        display: flex;
        align-items: baseline;
        padding: 0px 10px;

        img{
            width: auto;
            height: auto;
        }
        strong{
            font-size: 14px;
        }
    }
 

    .billbox_amountoff{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        img{
          
             object-fit: cover;
        }
    }

    .billbox_bottombtn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        .billbox_booknowbtn{
            width: auto;
            height: auto;
            font-size: 12px;
            padding: 12px 25px;
            border-radius: 5px;
            background-color: #000000;
        }
        .billbox_bottomtimer{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            margin-top: 5px;
            img{
                width: auto;
                height: auto;
            }
            small{
                font-size: 12px;
                opacity: 0.7;
            }
        }
    }

}
    

}